import classNames from "classnames";
import OpacityCover from "components/OpacityCover/OpacityCover";
import React, { CSSProperties, useState } from "react";
import styles from "./Modal.module.scss";
import { CloseOutlined } from "@ant-design/icons";
import Portal from "components/core/Portal/Portal";

type Props = {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  modalClassName?: string;
  width?: number;
  closeClassName?: string;
  fullWidthSmall?: boolean;
  modalClass?: string;
  modalStyle?: CSSProperties;
  blockOutsideClick?: boolean;
};

const Modal = (props: Props) => {
  const [closing, setClosing] = useState(false);

  const clickedOff = () => {
    setClosing(true);
    setTimeout(function () {
      setClosing(false);
      props.onClose();
    }, 250);
  };

  const stopClose = () => {
    return null;
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  if (props.visible === false) {
    return <></>;
  }

  return (
    <Portal containerSelector="#modal-container">
      {props.blockOutsideClick && (
        <>
          <OpacityCover
            onClick={stopClose}
            visible={props.visible}
            closing={closing}
          />
          <div
            className={classNames(styles.modalParent, props.modalClass, {
              [styles.fullWidthSmall]: props.fullWidthSmall,
            })}
          >
            <div
              className={classNames(
                props.visible
                  ? classNames(
                      styles.modal,
                      "container",
                      closing ? styles.closing : undefined
                    )
                  : styles.hidden,
                props.modalClassName,
                { [styles.fullWidth]: props.fullWidthSmall }
              )}
              style={{
                maxWidth: props.width ?? undefined,
                ...props.modalStyle,
              }}
              onClick={stopPropagation}
            >
              <CloseOutlined
                className={classNames(styles.closeButton, props.closeClassName)}
                onClick={clickedOff}
                data-cy="ModalClose"
              />
              <div className={props.className}>{props.children}</div>
            </div>
          </div>
        </>
      )}

      {!props.blockOutsideClick && (
        <>
          <OpacityCover
            onClick={clickedOff}
            visible={props.visible}
            closing={closing}
          />
          <div
            className={classNames(styles.modalParent, props.modalClass, {
              [styles.fullWidthSmall]: props.fullWidthSmall,
            })}
            onClick={props.onClose}
          >
            <div
              className={classNames(
                props.visible
                  ? classNames(
                      styles.modal,
                      "container",
                      closing ? styles.closing : undefined
                    )
                  : styles.hidden,
                props.modalClassName,
                { [styles.fullWidth]: props.fullWidthSmall }
              )}
              style={{
                maxWidth: props.width ?? undefined,
                ...props.modalStyle,
              }}
              onClick={stopPropagation}
            >
              <CloseOutlined
                className={classNames(styles.closeButton, props.closeClassName)}
                onClick={clickedOff}
                data-cy="ModalClose"
              />
              <div className={props.className}>{props.children}</div>
            </div>
          </div>
        </>
      )}
    </Portal>
  );
};

export default Modal;
